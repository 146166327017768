.first{
    padding-top:18rem;
    overflow-y: hidden;
}

.work{
    position: relative;
    padding: 10rem 8rem;
}
.project{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 5rem 5rem ;
}
.card {
    width: 300px;
    height: auto;
    background: #243137;
    position: relative;
    display: grid;
    place-content: center;
    border-radius: 10px;
    overflow: hidden;
    transition: all 0.5s ease-in-out;
    margin: 1rem;
  }
  
  #logo-main, #logo-second {
    height: 100%;
  }
  
  #logo-main {
    fill: #bd9f67;
  }
  
  #logo-second {
    padding-bottom: 10px;
    fill: none;
    stroke: #bd9f67;
    stroke-width: 1px;
  }
  
  .border {
    position: absolute;
    inset: 0px;
    border: 2px solid #bd9f67;
    opacity: 0;
    transform: rotate(10deg);
    transition: all 0.5s ease-in-out;
  }
  
  .bottom-text {
    position: absolute;
    left: 50%;
    bottom: 13px;
    transform: translateX(-50%);
    font-size: 6px;
    text-transform: uppercase;
    padding: 0px 5px 0px 8px;
    color: #bd9f67;
    background: #243137;
    opacity: 0;
    letter-spacing: 7px;
    transition: all 0.5s ease-in-out;
  }
  
  .content {
    transition: all 0.5s ease-in-out;
  }
  
  .content .logo {
    height: 35px;
    position: relative;
    width: 33px;
    overflow: hidden;
    transition: all 1s ease-in-out;
  }
  
  .content .logo .logo1 {
    height: 33px;
    position: absolute;
    left: 0;
  }
  
  .content .logo .logo2 {
    height: 33px;
    position: absolute;
    left: 33px;
  }
  
  .content .logo .trail {
    position: absolute;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
  }
  
  .content .logo-bottom-text {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    margin-top: 30px;
    color: #bd9f67;
    padding-left: 8px;
    font-size: 11px;
    opacity: 0;
    letter-spacing: none;
    transition: all 0.5s ease-in-out 0.5s;
  }
  
  .card:hover {
    border-radius: 0;
    transform: scale(1.1);
  }
  
  .card:hover .logo {
    width: 134px;
    animation: opacity 1s ease-in-out;
  }
  
  .card:hover .border {
    inset: 15px;
    opacity: 1;
    transform: rotate(0);
  }
  
  .card:hover .bottom-text {
    letter-spacing: 3px;
    opacity: 1;
    transform: translateX(-50%);
  }
  
  .card:hover .content .logo-bottom-text {
    opacity: 1;
    letter-spacing: 9.5px;
  }
  
  .card:hover .trail {
    animation: trail 1s ease-in-out;
  }
  
  @keyframes opacity {
    0% {
      border-right: 1px solid transparent;
    }
  
    10% {
      border-right: 1px solid #bd9f67;
    }
  
    80% {
      border-right: 1px solid #bd9f67;
    }
  
    100% {
      border-right: 1px solid transparent;
    }
  }
  
  @keyframes trail {
    0% {
      background: linear-gradient(90deg, rgba(189, 159, 103, 0) 90%, rgb(189, 159, 103) 100%);
      opacity: 0;
    }
  
    30% {
      background: linear-gradient(90deg, rgba(189, 159, 103, 0) 70%, rgb(189, 159, 103) 100%);
      opacity: 1;
    }
  
    70% {
      background: linear-gradient(90deg, rgba(189, 159, 103, 0) 70%, rgb(189, 159, 103) 100%);
      opacity: 1;
    }
  
    95% {
      background: linear-gradient(90deg, rgba(189, 159, 103, 0) 90%, rgb(189, 159, 103) 100%);
      opacity: 0;
    }
  }

  @media (max-width: 768px) {
    .project {
      padding: 5rem 5rem ;
  }

  .card {
      width: calc(100% - 2rem); 
      margin: 1rem 0; 
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .project {
    padding: 2rem;
}

.card {
    width: calc(50% - 2rem); /* Arrange cards in a grid on medium screens */
}
}
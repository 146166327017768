.about{
  position: relative;
  
}

.me {
  background-color: rgb(17, 35, 47);
  min-height: 100vh;
  position: relative;
  z-index: 1;
}
.about{
  z-index: -1;
}
.title{
    padding-top: 5rem;
    color:white;
    font-size: 35px;
    
}
.greet{
    line-height:20px;
    color:#FF7545;
    font-size: 18px;
    font-weight: lighter;
}

.main{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 5rem;
}

.skills{
    color: white;
    font-size: 20px;
   
    font-weight: 500;
    width: 700px;
    text-align: justify;
}
.content{
  color: white;
  text-align: center;
  padding-bottom: 5rem;
  font-size: large;
  align-items: center;
  padding: 0 10rem;
    
}
.content p{
    padding-bottom: 2rem;
    font-family:Arial, Helvetica, sans-serif;
    
}

.section {
  position: absolute;
  top: 80vh;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 2;
  width: 90%; 
  max-width: 50rem;
}

.features-small-item {
    background: #FFFFFF;
    box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.10);
    border-radius: 20px;
    padding: 10px;
    text-align: center;
    width: 100%; 
    left: 50%;
    max-width: 50rem;
    
  } 

  .grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr)); 
    gap: 20px; 
    padding: 10px;
}

.grid-item {
    display: flex; 
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    font-size: 2vw; 
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
    text-align: center;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}


.icon {
    animation: fadeIn 0.5s ease-in-out forwards;
}

/* Add a delay to each icon */
.icon:nth-child(1) {
    animation-delay: 0.2s;
}
.icon:nth-child(2) {
    animation-delay: 0.4s;
}
.icon:nth-child(3) {
    animation-delay: 0.6s;
}
/* Add similar delay styles for the other icons */


@media (max-width: 768px) {
    .features-small-item {
        width: 90%;
        padding-left: 20rem; /* Adjust the width for smaller screens */
    }
    .section{
      top:90vh
    }
    .features-small-item h4{
        font-size: 1.5rem;
    }
    .title{
      font-size: 25px;
    }
}


@media (min-width: 769px) and (max-width: 1024px) {
    .features-small-item {
        width: 70%; 
        padding-left: 15rem;
    }

    .features-small-item h4{
      font-size: 2.5rem;
  }  
  .section{
    top:80vh;
  }  
}


@media (min-width: 1025px) {
    .features-small-item {
        width: 50rem; 
    }
}

.button {
    position: relative;
    width: 150px;
    height: 40px;
    cursor: pointer;
    display: flex;
    align-items: center;
    border: 1px solid #FF7545;
    background-color: #FF7545;
    overflow: hidden;
    
  }
  
  .button, .button__icon, .button__text {
    transition: all 0.3s;
  }
  
  .button .button__text {
    transform: translateX(22px);
    color: #fff;
    font-weight: 600;
  }
  
  .button .button__icon {
    position: absolute;
    transform: translateX(109px);
    height: 100%;
    width: 39px;
    background-color: #FF7545;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .button .svg {
    width: 20px;
    fill: #fff;
  }
  
  .button:hover {
    background: #FF7545;
  }
  
  .button:hover .button__text {
    color: transparent;
  }
  
  .button:hover .button__icon {
    width: 148px;
    transform: translateX(0);
  }
  
  .button:active .button__icon {
    background-color: #FF7545;
  }
  
  .button:active {
    border: 1px solid #FF7545;
  }
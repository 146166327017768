.first-page{
    display: flex;
  
    align-items: center; 
    padding:0;
    height: auto;
    overflow:hidden;
}

.girl-img{
    max-width: 55%;
    padding-top: 2rem;
    
}

.intro{
    flex:1;
    padding-top: 10rem;
    font-size:45px;
    color:rgb(17, 35, 47);
}

.intro h1{
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.intro p{
    font-size: 4vh;
    color: #FF7545;
    font-weight:600;
    padding: 10px;
}


.social-media-icon{
    color: white;
    padding-right: 1rem;
    font-size: 2rem;
}

.social-media-icon a{
    padding-right: 1rem;
    color: rgb(17, 35, 47);
}

.wrapper{
    line-height: 0;
    display: flex;
    font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    padding-bottom: 20px;
    justify-content: center;
    padding-top: 4px;
}

.wrapper .static-txt{
    
    font-size: 3vh; 
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 400;
}

.wrapper .dynamic-txt{
    max-width: max-content;
    width: auto;
    font-size: 3vh; 
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 400;
}
@media (max-width: 768px) {
    
  .first-page {
    flex-direction: column; /* Stack items vertically on smaller screens */
    align-items: center;
}

.girl-img {
    max-width: 100%;
    padding-top: 0; 
}

  .static-txt {
      font-size: 10px;
    }
    
    .dynamic-txt {
      font-size: 10px;
    }
    .intro {
        font-size: 16px;
        padding-top: 0rem; 
        text-align: center;
      }
      .intro h1 {
        font-size: 5vw; 
      }
      .intro p {
        font-size: 12px; 
      }
      .social-media-icon {
        font-size: 1.5rem; 
      }
      
  }

  @media (min-width: 769px) {
    .static-txt {
      font-size: 24px;
    }
    .dynamic-txt {
      font-size: 20px;
    }
    .intro {
        font-size: 45px;
        padding-top: 12rem;
        padding-bottom: 5rem;
      }
      .intro h1 {
        font-size: 7vw;
      }
      .intro p {
        font-size: 15px;
      }
      .social-media-icon {
        font-size: 2rem;
      }
  }
  

  /* ... (previous styles) */

/* Define styles for smaller screens (e.g., mobile devices) */

  
  /* Define styles for larger screens (e.g., desktop) */
  @media (min-width: 769px) {
    .intro {
      font-size: 45px;
      padding-top: 12rem;
    }
    .intro h1 {
      font-size: 7vw;
    }
    .intro p {
      font-size: 15px;
    }
    .social-media-icon {
      font-size: 2rem;
    }
  }
